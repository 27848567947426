import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "../../../components/button";
import CheckBox from "../../../components/check-box";
import Input from "../../../components/input";
import ItemList2 from "../../../components/item-list-2";
import Search from "../../../components/search";
import SelectInput from "../../../components/select";
import TextArea from "../../../components/text-area";
import { get_status } from "../../../config";
// import { useHistory } from "react-router-dom";
import Select from "react-select";
import DepartureDetailsModal from "./DetailsModal";
import DeparteeModal from "./departee-modal";
import {
  clearDeparture,
  getAllDeparturesReasons,
  // getAllDepartures,
  CREATE_RECURRENT_DEPARTURE,
  createRecurentDeparture,
  getAllDepartures,
  UPDATE_DEPARTURE,
  get_report,
  GET_REPORT_DEPARTURE
} from "../../../redux/departures";
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";

const SalidasAdmin = (props) => {
  //React Hooks
  const formRef = useRef(null);
  const dispatch = useDispatch();

  //Reduz States
  const _get_all_departures = useSelector(
    (state) => state.departures_module._get_all_departures
  );
  const _create_recurrent_departure = useSelector(
    (state) => state.departures_module._create_recurrent_departure
  );
  const _get_all_departures_reasons = useSelector(
    (state) => state.departures_module._get_all_departures_reasons.data.datos
  );
  const { token } =
    useSelector((state) => state.users_module.login.data) || null;

  const _get_all_establishments = useSelector(
    (state) => state.establishment_module._get_all_establishments
  );
  const _update_departure = useSelector(
    (state) => state.departures_module._update_departure
  );
  const userEstablishmentsList = useSelector(
    (state) => state.users_module._session_data.data
  );
  const _get_report = useSelector((state) => state.departures_module._get_report);

  //Constants
  // eslint-disable-next-line
  const initForm = {
    id_razon: "",
    detalle: "",
    id_establecimiento: "",
    dias_semana: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  
  const daysOfWeek = [
    { name: "Lunes", id: 1 },
    { name: "Martes", id: 2 },
    { name: "Miércoles", id: 3 },
    { name: "Jueves", id: 4 },
    { name: "Viernes", id: 5 },
    { name: "Sábado", id: 6 },
    { name: "Domingo", id: 7 },
  ];

  const filter_options = [
    { name: "HOY", id: 1 },
    { name: "AYER", id: 2 },
    { name: "ÚLTIMOS 7 DÍAS", id: 3 },
    { name: "ÚLTIMOS 30 DÍAS", id: 4 },
    { name: "Rango de fechas", id: 5 },
  ];

  //useStates:
  // const [showDetail, setShowDetail] = useState(false);
  const [showSibarLeft, setShowSibarLeft] = useState(false);
  const [showSibarRight, setShowSibarRight] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  // const [report, setReport] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowId, setSelectedRowID] = useState("");
  const [departures, setDepartures] = useState([]);
  const [count, setCount] = useState(0);
  const [HasMore, setHasMore] = useState(true);
  const [permisos, setPermisos] = useState([]);
  const [establishment_select, setEstablishmentSelect] = useState([]);
  const [establishment, setEstablishment] = useState([]);
  const [n_page, setNPage] = useState(0);
  const [range_date, setRangeDate] = useState({
    visible: false,
    date_start: "",
    date_end: "",
  });
  const [departeemodal] = useState({
    ruc: "",
    nombres: "",
    apellidos: "",
    foto: "",
  });

  // eslint-disable-next-line
  const [form, setForm] = useState(initForm);
  // eslint-disable-next-line
  const [filtros, setFiltros] = useState({
    creadas: false,
    salidas_aprobadas: false,
    denegadas: false,
    caducadas: false,
    espera: false,
    salida_denegada: false,
    salida_rechazada: false,
    aceptadas: false,
    retorno_requerido: false,
    retorno_aceptado: false,
    retorno_rechazado: false,
    search: "",
  });

 /*  useEffect(() => {
    setReport(_get_report.status)
}, [_get_report]) */


  //useEffects:
  useEffect(() => {
    dispatch(getAllDeparturesReasons(token));
  }, [token, dispatch]);

  useEffect(() => {
    let array = [];
    if (_get_all_establishments.data) {
      if (_get_all_establishments.data.datos) {
        array = _get_all_establishments.data.datos.map((element) => {
          return {
            value: element.id,
            label: element.nombre,
          };
        });
      }
    }
    setEstablishment(array);
  }, [_get_all_establishments.data]);

  useEffect(() => {
    if (_create_recurrent_departure.status === 201) {
      toast.success("Salida creada correctamente", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setForm(initForm);
      setEstablishmentSelect([]);
      setShowSibarRight(false);
      setShowOverlay(!showSibarRight);
      dispatch(clearDeparture(CREATE_RECURRENT_DEPARTURE));
      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      if (formRef.current) {
        formRef.current.reset();
      }
      setIsLoading(false);
    } else if (_create_recurrent_departure.status !== 0) {
      toast.error(_create_recurrent_departure.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearDeparture(CREATE_RECURRENT_DEPARTURE));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_create_recurrent_departure, dispatch]);

  useEffect(() => {
    if (_update_departure.status === 200) {
      toast.success(_update_departure.message);
      dispatch(clearDeparture(UPDATE_DEPARTURE));

      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      setForm(initForm);
      setShowOverlay(false);
      setShowSibarRight(false);
      setIsLoading(false);
    } else if (_update_departure.status !== 0) {
      toast.error(_update_departure.message);
      dispatch(clearDeparture(UPDATE_DEPARTURE));
      setForm(initForm);
      setShowOverlay(false);
      setShowSibarRight(false);
      setIsLoading(false);
    }
  }, [_update_departure, dispatch, token, initForm, userEstablishmentsList]);

  useEffect(() => {
    if (props) {
      //converto to array
      const array = Object.values(props);
      setPermisos(array);
    }
    if (_get_all_departures.data.datos) {
      setDepartures(_get_all_departures.data.datos);
      setNPage(_get_all_departures.data.n_page);
    }
  }, [_get_all_departures, count, props]);

  //function handlers
  function setOpen() {
    setOpenModal(!openModal);
  }

  const loadFunc = () => {
    if (_get_all_departures.data.datos) {
      if (_get_all_departures.data.datos.length > count) {
        const ordenado = _get_all_departures.data.datos.sort((a, b) => {
          return (
            new Date(b.date + " 00:00:00") - new Date(a.date + " 00:00:00")
          );
        });
        setDepartures([...departures, ...ordenado.slice(count, count + 10)]);
        setCount(count + 10);
      }
      setHasMore(false);
    }
  };

  const filterToday = (e) => {
    let salidas = [];
    let today = new Date();
    salidas = departures;

    let actual = [];
    if (
      filtros.salidas_aprobadas ||
      filtros.caducadas ||
      filtros.creadas ||
      filtros.espera ||
      filtros.salida_denegada ||
      filtros.salida_rechazada ||
      filtros.aceptadas ||
      filtros.retorno_aceptado ||
      filtros.retorno_rechazado ||
      filtros.retorno_requerido ||
      filtros.search
    ) {
      if (filtros.salidas_aprobadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA APROBADA"
          )
        );
      }
      if (filtros.caducadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "CADUCADO"
          )
        );
      }
      if (filtros.creadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "CREADO"
          )
        );
      }
      if (filtros.espera) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA REQUERIDA"
          )
        );
      }
      if (filtros.salida_denegada) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA DENEGADA"
          )
        );
      }
      if (filtros.salida_rechazada) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA RECHAZADA"
          )
        );
      }
      if (filtros.aceptadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA ACEPTADA"
          )
        );
      }
      if (filtros.retorno_requerido) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "RETORNO REQUERIDO"
          )
        );
      }
      if (filtros.retorno_aceptado) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "RETORNO ACEPTADO"
          )
        );
      }
      if (filtros.retorno_rechazado) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "RETORNO RECHAZADO"
          )
        );
      }
    } else {
      actual = _get_all_departures.data.datos;
    }

    if (e.target.value === "0") {
      setDepartures(actual);
    }

    //HOY
    if (e.target.value === "1") {
      // eslint-disable-next-line
      salidas = actual.filter((salida) => {
        if (salida.date) {
          let fecha = new Date(salida.date + " 00:00:00");
          if (
            fecha.getDate() === today.getDate() &&
            fecha.getMonth() === today.getMonth() &&
            fecha.getFullYear() === today.getFullYear()
          ) {
            return salida;
          }
        }
      });
      setDepartures(salidas);

      return;
    }

    //AYER
    if (e.target.value === "2") {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      // eslint-disable-next-line
      salidas = actual.filter((salida) => {
        if (salida.date) {
          let fecha = new Date(salida.date + " 00:00:00");
          if (
            fecha.getDate() === today.getDate() &&
            fecha.getMonth() === today.getMonth() &&
            fecha.getFullYear() === today.getFullYear()
          ) {
            return salida;
          }
        }
      });
      setDepartures(salidas);

      return;
    }

    //ÚLTIMOS 7 DÍAS
    if (e.target.value === "3") {
      let today = new Date();

      today.setDate(today.getDate() - 7);
      // eslint-disable-next-line
      salidas = actual.filter((salida) => {
        if (salida.date) {
          let fecha = new Date(salida.date + " 00:00:00");
          if (
            fecha.getTime() <= new Date().getTime() &&
            fecha.getTime() >= today.getTime()
          ) {
            return salida;
          }
        }
      });
      setDepartures(salidas);

      return;
    }

    //ÚLTIMOS 30 DÍAS
    if (e.target.value === "4") {
      let today = new Date();
      today.setDate(today.getDate() - 30);
      // eslint-disable-next-line
      salidas = actual.filter((salida) => {
        if (salida.date) {
          let fecha = new Date(salida.date + " 00:00:00");
          if (
            fecha.getTime() >= today.getTime() &&
            fecha.getTime() <= new Date().getTime()
          ) {
            return salida;
          }
        }
      });
      setDepartures(salidas);

      return;
    }

    if (e.target.value === "5") {
      setRangeDate({
        date_end: "",
        date_start: "",
        visible: true,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "id_establecimiento") {
      setForm({
        ...form,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeEstablishment = (e) => {
    setForm({
      ...form,
      // eslint-disable-next-line
      ["id_establecimiento"]: parseInt(e.value),
    });
    setEstablishmentSelect(e);
  };

  const handleFilterState = (e, type) => {
    if (type === "CREADO") {
      filtros.creadas = e.target.checked;
    }
    if (type === "SALIDA APROBADA") {
      filtros.salidas_aprobadas = e.target.checked;
    }
    if (type === "CADUCADO") {
      filtros.caducadas = e.target.checked;
    }
    if (type === "SALIDA REQUERIDA") {
      filtros.espera = e.target.checked;
    }
    if (type === "SALIDA DENEGADA") {
      filtros.salida_denegada = e.target.checked;
    }
    if (type === "SALIDA RECHAZADA") {
      filtros.salida_rechazada = e.target.checked;
    }
    if (type === "SALIDA ACEPTADA") {
      filtros.aceptadas = e.target.checked;
    }
    if (type === "RETORNO REQUERIDO") {
      filtros.retorno_requerido = e.target.checked;
    }
    if (type === "RETORNO ACEPTADO") {
      filtros.retorno_aceptado = e.target.checked;
    }
    if (type === "RETORNO RECHAZADO") {
      filtros.retorno_rechazado = e.target.checked;
    }
    let actual = [];

    if (filtros.salidas_aprobadas && type !== "SALIDA APROBADA") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "SALIDA APROBADA"
        )
      );
    }
    if (filtros.caducadas && type !== "CADUCADO") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "CADUCADO"
        )
      );
    }
    if (filtros.creadas && type !== "CREADO") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "CREADO"
        )
      );
    }
    if (filtros.espera && type !== "SALIDA REQUERIDA") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "SALIDA REQUERIDA"
        )
      );
    }
    if (filtros.salida_denegada && type !== "SALIDA DENEGADA") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "SALIDA DENEGADA"
        )
      );
    }
    if (filtros.salida_rechazada && type !== "SALIDA RECHAZADA") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "SALIDA RECHAZADA"
        )
      );
    }
    if (filtros.aceptadas && type !== "SALIDA ACEPTADA") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "SALIDA ACEPTADA"
        )
      );
    }
    if (filtros.retorno_requerido && type !== "RETORNO REQUERIDO") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "RETORNO REQUERIDO"
        )
      );
    }
    if (filtros.retorno_rechazado && type !== "RETORNO RECHAZADO") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "RETORNO RECHAZADO"
        )
      );
    }
    if (filtros.retorno_aceptado && type !== "RETORNO ACEPTADO") {
      actual = actual.concat(
        _get_all_departures.data.datos.filter(
          (x) => x.state_description === "RETORNO ACEPTADO"
        )
      );
    }

    if (e.target.checked) {
      actual.concat();
      let select = _get_all_departures.data.datos.filter(
        (x) => x.state_description === type
      );
      actual = actual.concat(select);
      // const ordenado = actual.sort((a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // });
      setDepartures(actual);
    } else {
      if (
        !filtros.salidas_aprobadas &&
        !filtros.caducadas &&
        !filtros.creadas &&
        !filtros.espera &&
        !filtros.salida_denegada &&
        !filtros.salida_pendiente &&
        !filtros.salida_rechazada
      ) {
        actual = _get_all_departures.data.datos;
      } else {
        actual.filter((x) => x.state_description === type).pop();
      }
      // const ordenado = actual.sort((a, b) => {
      //     return new Date(b.date) - new Date(a.date);
      // });
      setDepartures(actual);
    }
  };

  const handleCreateDeparture = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(createRecurentDeparture(token, form));
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setDepartures(_get_all_departures.data.datos);
      return;
    }
    setDepartures([]);
    let nombre = e.target.value;
    nombre = nombre.trim();
    let re = new RegExp(nombre, "i");
    // eslint-disable-next-line
    let filtro = _get_all_departures.data.datos.filter((element) => {
      if (element.establishment_name.match(re) != null) {
        return element;
      }
    });
    setDepartures(filtro);
  };

  const handleFilterRangeDate = (e) => {
    if (e.target.name === "date_start") {
      range_date.date_start = e.target.value;
    }
    if (e.target.name === "date_end") {
      range_date.date_end = e.target.value;
    }

    let actual = [];
    if (
      filtros.salidas_aprobadas ||
      filtros.caducadas ||
      filtros.creadas ||
      filtros.espera ||
      filtros.salida_denegada ||
      filtros.salida_rechazada
    ) {
      if (filtros.salidas_aprobadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA APROBADA"
          )
        );
      }
      if (filtros.caducadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "CADUCADO"
          )
        );
      }
      if (filtros.creadas) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "CREADO"
          )
        );
      }
      if (filtros.espera) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA REQUERIDA"
          )
        );
      }
      if (filtros.salida_denegada) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA DENEGADA"
          )
        );
      }
      if (filtros.salida_rechazada) {
        actual = actual.concat(
          _get_all_departures.data.datos.filter(
            (x) => x.state_description === "SALIDA RECHAZADA"
          )
        );
      }
    } else {
      actual = _get_all_departures.data.datos;
    }

    if (range_date.date_start !== "" && range_date.date_end !== "") {
      let date_start = new Date(range_date.date_start + " 00:00:00");
      let date_end = new Date(range_date.date_end + " 00:00:00");
      // eslint-disable-next-line
      let final = actual.filter((x) => {
        if (x.date) {
          let fecha = new Date(x.date + " 00:00:00");
          if (
            fecha.getTime() >= date_start.getTime() &&
            fecha.getTime() <= date_end.getTime()
          ) {
            return x;
          }
        }
      });

      setDepartures(final);
    }
  };

  const handleCheckboxChange = (day) => {
    if (form.dias_semana.includes(day)) {
      setForm({
        ...form,
        dias_semana: form.dias_semana.filter((d) => d !== day),
      });
    } else {
      setForm({ ...form, dias_semana: [...form.dias_semana, day] });
    }
  };
  // const handleAprove = (e) => {
  //   e.preventDefault();
  // };

  // const handleGenerateReport = () => {
  //     let final = []
  //     departures.forEach(e => {
  //         final.push(e.id)
  //     })

  //     dispatch(get_report({'lista_salidas': final}, token))
  // }

  //html

  const [page, setPage] = useState(1);

  function more_data() {
    let count = page + 1;
    setPage(count)
    dispatch(
      getAllDepartures(
        token,
        userEstablishmentsList.establecimientos?.map((par) => par.id),
        count
      )
    );
  }

  function less_data() {
      let count = page - 1;
      setPage(count)
      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          count
        )
      );
  }

  const [fecha_inicial, setFechaInicial] = useState('');
  const [fecha_final, setFechaFinal] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (_get_report.status === 200) {
        toast.success(_get_report.data.mensaje);
        dispatch(clearDeparture(GET_REPORT_DEPARTURE));
        setModal(false);
        setIsLoading(false);
    } else if (_get_report.status !== 0) {
        toast.error(_get_report.message);
        dispatch(clearDeparture(GET_REPORT_DEPARTURE));
        setModal(false);
        setIsLoading(false);
    }
  }, [_get_report, dispatch]);

  const handleGenerateReport = (e) => {
      e.preventDefault();
      setIsLoading(true);
      if (fecha_inicial === "") {
          toast.error("Debe seleccionar la fecha inicial");
          return;
      }
      if (fecha_final === "") {
          toast.error("Debe seleccionar la fecha final");
          return;
      }
      // setReport(false);
      setModal(false);
      dispatch(get_report({'fecha_inicio': fecha_inicial, 'fecha_fin': fecha_final}, token))
  }

  return (
    <div className='app-content content'>
      <ToastContainer />
      {isLoading && <LoadingSpinner/>}
      <div className='content-wrapper'>
        <div className='content-header row'>
          <div className='content-header-left col-12 mb-2 '>
            <Breadcrumbs
              title='Salidas'
              items={[{ label: "Listado Salidas", link: "/" }]}
              onclick
            />
          </div>
        </div>
        <div className='content-body'>
          <div className='content-area-wrapper' style={{ margin: 0 }}>
            <div className={`sidebar-left ${showSibarLeft ? "show" : ""}`}>
              <div className='sidebar'>
                <div className='sidebar-content email-app-sidebar d-flex mb-20'>
                  {/* sidebar close icon */}
                  <span
                    className='sidebar-close-icon'
                    onClick={() => {
                      setShowSibarLeft(false);
                      setShowOverlay(false);
                    }}
                  >
                    <i className='bx bx-x' />
                  </span>
                  {/* sidebar close icon */}
                  <div className='email-app-menu'>
                    <div className='form-group form-group-compose'>
                      {/* compose button  */}
                      {permisos.filter(
                        (element) =>
                          element.codigo === "add_recurringdepartures"
                      ).length > 0 ? (
                        <Button
                          text='Crear'
                          onChange={() => {
                            setShowSibarRight(!showSibarRight);
                            setShowSibarLeft(false);
                            // setShowDetail(false);
                            setForm(initForm);
                            setShowOverlay(!showSibarRight);
                            setEstablishmentSelect([]);
                          }}
                          theme='success'
                          classes=' btn-block my-2 compose-btn'
                        />
                      ) : null}
                    </div>
                    <div className='sidebar-menu-list'>
                      {/* sidebar menu  */}
                      <div className='list-group list-group-messages'>
                        <SelectInput
                          id='filtro-salida'
                          name='filtro-salida'
                          options={filter_options}
                          onChange={filterToday}
                        />
                        <ul className='list-unstyled mb-5'>
                          {range_date.visible ? (
                            <>
                              <li className=' mr-2 mb-1'>
                                <Input
                                  type='date'
                                  label='Fecha Inicio'
                                  onChange={handleFilterRangeDate}
                                  name='date_start'
                                />
                                <Input
                                  type='date'
                                  label='Fecha Fin'
                                  onChange={handleFilterRangeDate}
                                  name='date_end'
                                />
                              </li>
                              <hr />
                            </>
                          ) : null}

                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='CREADAS'
                              name='check-creadas'
                              id='check-creadas'
                              onChange={(e) => {
                                handleFilterState(e, "CREADO");
                              }}
                              state={filtros.creadas}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='SALIDAS APROBADAS'
                              name='check-salidas-aprobadas'
                              id='check-salidas-aprobadas'
                              onChange={(e) => {
                                handleFilterState(e, "SALIDA APROBADA");
                              }}
                              state={filtros.salidas_aprobadas}
                            />
                          </li>

                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='SALIDAS REQUERIDAS'
                              name='check-espera'
                              id='check-espera'
                              onChange={(e) => {
                                handleFilterState(e, "SALIDA REQUERIDA");
                              }}
                              state={filtros.espera}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='SALIDAS DENEGADAS'
                              name='check-salida_denegada'
                              id='check-salida_denegada'
                              onChange={(e) => {
                                handleFilterState(e, "SALIDA DENEGADA");
                              }}
                              state={filtros.salida_denegada}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='SALIDAS ACEPTADAS'
                              name='check-aceptadas'
                              id='check-aceptadas'
                              onChange={(e) => {
                                handleFilterState(e, "SALIDA ACEPTADA");
                              }}
                              state={filtros.aceptadas}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='SALIDAS RECHAZADAS'
                              name='check-salida_rechazada'
                              id='check-salida_rechazada'
                              onChange={(e) => {
                                handleFilterState(e, "SALIDA RECHAZADA");
                              }}
                              state={filtros.salida_rechazada}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='RETORNO REQUERIDO'
                              name='check-retorno-requerido'
                              id='check-retorno-requerido'
                              onChange={(e) => {
                                handleFilterState(e, "RETORNO REQUERIDO");
                              }}
                              state={filtros.retorno_requerido}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='RETORNO ACEPTADO'
                              name='check-retorno-aceptado'
                              id='check-retorno-aceptado'
                              onChange={(e) => {
                                handleFilterState(e, "RETORNO ACEPTADO");
                              }}
                              state={filtros.retorno_aceptado}
                            />
                          </li>
                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='RETORNO RECHAZADO'
                              name='check-retorno-rechazado'
                              id='check-retorno-rechazado'
                              onChange={(e) => {
                                handleFilterState(e, "RETORNO RECHAZADO");
                              }}
                              state={filtros.retorno_rechazado}
                            />
                          </li>

                          <li className=' mr-2 mb-1'>
                            <CheckBox
                              label='CADUCADAS'
                              name='check-caducadas'
                              id='check-caducadas'
                              onChange={(e) => {
                                handleFilterState(e, "CADUCADO");
                              }}
                              state={filtros.caducadas}
                            />
                          </li>
                        </ul>
                      </div>
                      {/* sidebar label end */}
                    </div>
                  </div>
                </div>
                {/* User new mail right area */}
                {(permisos.filter(
                  (element) => element.codigo === "add_recurringdepartures"
                ).length > 0 && openModal === false)? (
                  <div
                    className={`compose-new-mail-sidebar ${
                      showSibarRight ? "show" : ""
                    }`}
                    
                  >
                    <div className='card shadow-none quill-wrapper p-0'>
                      <div className='card-header'>
                        <h3 className='card-title' id='salida'>
                          Nueva Salida recurrente
                        </h3>
                        <button
                          type='button'
                          className='close close-icon'
                          onClick={() => {
                            setShowSibarRight(false);
                            setShowSibarLeft(false);
                            setShowOverlay(false);
                          }}
                        >
                          <i className='bx bx-x' />
                        </button>
                      </div>
                      {/* form start CREATE DEPARTURE */}
                      <form
                        ref={formRef}
                        onSubmit={handleCreateDeparture}
                        id='compose-form'
                      >
                        <div className='card-content'>
                          <div className='card-body pt-0'>
                            <label>Motivo</label>
                            <select
                              className='form-control'
                              name='id_razon'
                              required={true}
                              onChange={handleChange}
                            >
                              <option value={0} hidden>
                                Seleccione un motivo
                              </option>
                              {_get_all_departures_reasons !== undefined ? (
                                _get_all_departures_reasons
                                  .filter((data) =>
                                    data.tipo.includes("RECURRENTE") && data.activo === true
                                  )
                                  .map((item, index) => (
                                    <option key={item.id} value={item.id}>
                                      {item.descripcion}
                                    </option>
                                  ))
                              ) : (
                                <h5>No hay motivos disponibles</h5>
                              )}
                            </select>
                            <br/>
                            <TextArea
                              id='detalle'
                              name='detalle'
                              label='Motivo de la salida'
                              placeholder=''
                              onChange={handleChange}
                              rows={4}
                              value={form.detalle}
                            />
                            <label className='form-label'>
                              Establecimientos
                            </label>
                            <Select
                              options={establishment}
                              isMulti={false}
                              onChange={handleChangeEstablishment}
                              value={establishment_select}
                            />
                            <br />
                            <label className='form-label'>
                              Dias de la semana
                            </label>
                            <div>
                              {daysOfWeek.map((day) => (
                                <div key={day.id}>
                                  <label>
                                    <input
                                      type='checkbox'
                                      value={day.name}
                                      checked={form.dias_semana.includes(
                                        day.id
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(day.id)
                                      }
                                    /> {' '}
                                    {day.name}
                                  </label>
                                </div>
                              ))}
                              <div>
                                Selected Days:{" "}
                                {form.dias_semana.length > 0
                                  ? form.dias_semana.join(", ")
                                  : "None"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end pt-0'>
                          <Button
                            text='Cancelar'
                            theme='light-secondary'
                            type='reset'
                            classes='cancel-btn mr-1'
                            onClick={() => {
                              setShowSibarRight(false);
                              setShowOverlay(!showOverlay);
                            }}
                          />
                          <Button
                            text='Guardar'
                            onChange={() => {
                              //TODO: AGREGAR FUNCIONALIDAD DE CREAR VISITA
                            }}
                            theme='success'
                            type='submit'
                          />
                        </div>
                      </form>
                      {/* form start end*/}
                    </div>
                  </div>
                ) : null}
                {/*/ User Chat profile right area */}
              </div>
            </div>
            <div className='content-right'>
              <div className='content-overlay' />
              <div className='content-wrapper'>
                <div className='content-header row' />
                <div className='content-body'>
                  {/* email app overlay */}
                  <div
                    className={`app-content-overlay ${
                      showOverlay ? "show" : ""
                    }`}
                    onClick={() => {
                      setShowSibarRight(!showSibarRight);
                      setShowSibarLeft(false);
                      // setShowDetail(false);
                      setShowOverlay(!showOverlay);
                    }}
                  />
                  <div className='email-app-area'>
                    <div className='email-app-list-wrapper'>
                      <div className='email-app-list'>
                        <div
                          className='email-action'
                          style={
                            openModal === true ? { display: "none" } : null
                          }
                        >
                          <div className='action-right d-flex flex-grow-1 align-items-center justify-content-around'>
                            <div
                              className='email-fixed-search flex-grow-1'
                              style={
                                openModal === true ? { display: "none" } : null
                              }
                            >
                              <div
                                className='sidebar-toggle d-block d-lg-none'
                                onClick={() => {
                                  setShowSibarLeft(!showSibarLeft);
                                  setShowOverlay(!showOverlay);
                                }}
                              >
                                <i className='bx bx-menu' />
                              </div>{" "}
                              <div className="row">
                                <div className="col-10">
                                    <Search placeholder={"Buscar por establecimiento"}
                                            onChange={handleSearch}
                                    />
                                </div>
                                <div className="col-2">
                                  <Button
                                    text={<h3><i className="text-white bx bx-export"/></h3>}
                                    theme="success"
                                    type="button"
                                    classes="btn-lg round"
                                    onClick={() => {setModal(true); setFechaInicial(''); setFechaFinal('');}}
                                  />
                                    {/* {!report ? <Button text={
                                        <h3><i className="text-white bx bx-export"/>
                                        </h3>
                                    } theme="success" type="button"
                                                        classes="btn-lg round"
                                                        onClick={() => {setModal(true); setFechaInicial(''); setFechaFinal('');}}
                                    /> : <div class="spinner-border text-success"
                                              role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>} */}
                                </div>
                              </div>
                            </div>
                            {/* pagination and page count */}
                          </div>
                        </div>
                        <div className="row" style={{marginTop: 10, marginBottom: 5}}>
                            <div className="col-4">
                                <label className="ml-1">Página {page}</label>
                            </div>
                            <div className="col-8 text-right">
                                {(page > 1 && page <= n_page) && 
                                    <button className="btn btn-primary mr-1" type="button" onClick={() => {less_data();}}><i class="bx bx-arrow-back"></i></button>
                                }
                                {(page >= 1 && page < n_page) && 
                                    <button className="btn btn-success" type="button" onClick={() => {more_data();}}><i class="bx bx-right-arrow-alt"></i></button>
                                }
                            </div>
                        </div>
                        <div
                          className='email-user-list list-group'
                          style={openModal === true ? { display: "none" } : {}}
                        >
                          <ul
                            className='users-list-wrapper media-list'
                            style={{
                              overflowY: "scroll",
                            }}
                          >
                            <InfiniteScroll
                              pageStart={0}
                              loadMore={() => loadFunc()}
                              hasMore={HasMore}
                              useWindow={false}
                              loader={
                                <div className='col-12 d-flex justify-content-center'>
                                  <div
                                    className='spinner-border text-success'
                                    role='status'
                                  >
                                    <span className='sr-only'>Loading...</span>
                                  </div>{" "}
                                </div>
                              }
                            >
                              {departures.map((item, index) => (
                                <ItemList2
                                  item={item}
                                  title={item.establishment_name}
                                  reason_details
                                  subtitle={item.reason_desciption}
                                  rightDescription={`${item.type_reason}`}
                                  SecondrightDescription={`Estado: ${item.state_description}`}
                                  bulletType={get_status(
                                    item.state_description
                                  )}
                                  children={
                                    <div className='user-details'>
                                      {item.fecha_solicitud_aprobacion && (
                                        <div className='mail-items'>
                                          <span>
                                            Fecha de solicitud de aprobación:{" "}
                                          </span>
                                          {item.fecha_solicitud_aprobacion}
                                        </div>
                                      )}
                                      {item.fecha_respuesta_aprobacion && (
                                        <div className='mail-meta-item'>
                                          <span>Fecha de aprobación: </span>
                                          {item.fecha_respuesta_aprobacion}
                                        </div>
                                      )}
                                    </div>
                                  }
                                  onClick={() => {
                                    setSelectedRowID(item.departure_id);
                                    setOpenModal(true);
                                  }}
                                />
                              ))}
                            </InfiniteScroll>
                          </ul>
                          <div className='no-results'>
                            <i className='bx bx-error-circle font-large-2' />
                            <h5>No Items Found</h5>
                          </div>
                        </div>
                        {openModal === true ? (
                          <DepartureDetailsModal
                            open={openModal}
                            setOpen={setOpen}
                            departure_id={selectedRowId}
                          />
                        ) : null}
                      </div>
                    </div>
                    <DeparteeModal item={departeemodal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal className="modal-main" show={modal} style={{width: "25%"}}>
          <form onSubmit={handleGenerateReport}>
              <div className="card-body">
              <h5 className="mb-2">Exportar datos</h5>
                  <div className="form-group">
                      <Input
                          type="date"
                          label="Fecha Inicio"
                          onChange={(e) => {setFechaInicial(e.target.value)}}
                          name='fecha_inicial'
                          value={fecha_inicial}
                      />
                      <Input
                          type="date"
                          label="Fecha Fin"
                          onChange={(e) => {setFechaFinal(e.target.value)}}
                          name='fecha_final'
                          value={fecha_final}
                      />
                  </div>
              </div>
              <div className="card-footer text-center">
                  <button className={`btn btn-success mb-1 button_div`}>Exportar</button>
                  <button type="button" onClick={() => {
                      setFechaInicial('');
                      setFechaFinal('');
                      setModal(false);
                      }} className={`btn btn-outline-danger button_div`}>Cancelar</button>
              </div>
          </form>
      </Modal>
    </div>
  );
};

export default SalidasAdmin;
