import React, { useEffect, useState } from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import { URLAPI, get_status } from "../../../config";
import Card from "../../../components/card";
import {
  clearDeparture,
  updateDepartureRequired,
  UPDATE_DEPARTURE,
  getAllDepartures,
  getDepartureDetails,
} from "../../../redux/departures";
import { useDispatch, useSelector } from "react-redux";
import CardItem from "../../../components/card-item";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
// import VisitantModal from "./departure-modal";
import { toast, ToastContainer } from "react-toastify";
import DeparteeModal from "./departee-modal";
import LoadingSpinner from "../../../components/loading-spinner";
import Modal from "../../../components/modal";
import SelectInput from "../../../components/select";

const DepartureDetails = (props) => {
  const { departure_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isToday, setIsToday] = useState(false);

  const userEstablishmentsList = useSelector(
    (state) => state.users_module._session_data.data
  );
  const { token } = useSelector((state) => state.users_module.login.data) || null;

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [id_empleado, setIdEmpleado] = useState(0);
  
  /* const _get_all_departures = useSelector(
    (state) => state.departures_module._get_all_departures
  ); */
  
  const _get_departure_details = useSelector(
    (state) => state.departures_module._get_departure_details
  );
  
  const _update_departure = useSelector(
    (state) => state.departures_module._update_departure
  );

  const [DataSelected, setDataSelected] = useState({
    departure_id: 0,
    id_empleado: 0,
    establishment_id: "",
    establishment_name: "",
    reason_description: "",
    reason_decline_description: "",
    reason_details: "",
    status_description: "",
    state_id: "",
    absence_days: "",
    date: "",
    date_approval: "",
    date_create: "",
    date_request_exit: null,
    date_request_return: null,
    date_response_exit: null,
    date_response_return: null,
    employee_info: {},
    employees: [],
  });

  useEffect(() => {
    dispatch(getDepartureDetails(token, departure_id));
  }, [departure_id, dispatch, token]);

  useEffect(() => {
    if (_update_departure.status === 200) {
      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      toast.success(_update_departure.message);
      dispatch(clearDeparture(UPDATE_DEPARTURE));
      setIsLoading(false);
      history.push(`/`);
    } else if (_update_departure.status !== 0) {
      toast.error(_update_departure.message);
      dispatch(clearDeparture(UPDATE_DEPARTURE));
      setIsLoading(false);
    }
  }, [_update_departure, dispatch, token, userEstablishmentsList, history]);

  useEffect(() => {
    if (_get_departure_details && _get_departure_details.data && _get_departure_details.data.datos) {
      setDataSelected(_get_departure_details.data.datos[0]);
    }
  }, [_get_departure_details, departure_id, DataSelected]);

  // useEffect(() => {
  //   console.log(DataSelected);
  // }, [DataSelected]);

  const handleRequire = (e) => {
    e.preventDefault();
    if (DataSelected.departure_type_description === 'RECURRENTE') {
      setModal(true);
    } else {
      setIsLoading(true);
      setDataSelected({
        ...DataSelected,
        status_description: "SALIDA REQUERIDA",
      });
      let data = { estado: "SALIDA REQUERIDA" };
      dispatch(updateDepartureRequired(token, DataSelected.departure_id, data));
    }
  };

  const handleRequireRecurrent = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (id_empleado === 0) {
      toast.error("Debe seleccionar un empleado");
      setIsLoading(false);
      return;
    }
    setDataSelected({
      ...DataSelected,
      status_description: "SALIDA REQUERIDA",
    });
    let data = { estado: "SALIDA REQUERIDA", id_empleado: id_empleado };
    dispatch(updateDepartureRequired(token, DataSelected.departure_id, data));
  };

  const handleRequireRetorno = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDataSelected({
      ...DataSelected,
      status_description: "RETORNO REQUERIDO",
    });
    let data = { estado: "RETORNO REQUERIDO"};
    dispatch(updateDepartureRequired(token, DataSelected.departure_id, data));
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  useEffect(() => {
    const formattedToday = `${year}-${month}-${day}`;

    if (formattedToday === DataSelected.date) {
      setIsToday(false);
    } else {
      setIsToday(true);
    }
  }, [DataSelected.date, year, month, day]);

  const handleChangeEmployee = (e) => {
    setIdEmpleado(parseInt(e.target.value));
}

  return (
    <div className='app-content content'>
      <div className='content-overlay' />
      <ToastContainer />
      {isLoading && <LoadingSpinner/>}
      <div className='content-wrapper'>
        <div className='content-header row'>
          <div className='content-header-left col-12 mb-2 '>
            <Breadcrumbs
              title='Salidas'
              items={[{ label: "Listado Salidas", link: "/" }]}
            />
          </div>
        </div>
        <div className='content-body'>
          <div className='app-content-overlay' />
          <div className='email-app-area'>
            <div className='email-app-list-wrapper'>
              <div className='email-app-list'>
                <div className='email-action'>
                  <div className='action-right d-flex flex-grow-1 align-items-center justify-content-around'></div>
                </div>
              </div>
            </div>
            <div
              className='email-app-details'
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#DFE3E7",
              }}
            >
              <div
                className='navbar navbar-light bg-white p-2'
                style={{
                  borderBottom: "1px solid #DFE3E",
                }}
              >
                <div className='email-header-left d-flex align-items-center mb-1'>
                  <Link to='/' className='go-back mr-50'>
                    <span className='fonticon-wrap d-inline'>
                      <i className='bx bx-arrow-back' />
                    </span>
                  </Link>
                  <h5 className='email-detail-title font-weight-normal mb-0'>
                    {DataSelected.reason_description}
                  </h5>
                </div>
                <div className='email-header-right mb-1 ml-2 pl-1'>
                  <span
                    className={`badge badge-light-${get_status(
                      DataSelected.status_description
                    )} badge-pill ml-1`}
                  >
                    {DataSelected.status_description}
                  </span>
                </div>
              </div>
              {/* email detail view header end*/}
              <div className='email-scroll-area'>
                {/* email details  */}
                <div className='row'>
                  <div className='col-12 p-3'>
                    <div className='collapsible email-detail-head'>
                      <Card
                        title={DataSelected.establishment_name}
                        description={
                          <>
                            <span>Fecha de salida: </span>
                            <strong>
                              {new Date(
                                DataSelected.date + " 00:00:00"
                              ).toLocaleDateString("es-ES", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </strong>
                            <p />
                            {"Tipo de salida: "}
                            <strong>
                              {DataSelected.reason_description}
                            </strong>{" "}
                            <br />
                          </>
                        }
                        subtitle={
                          // DataSelected.status_description === "CREADO" ||
                          DataSelected.status_description ===
                          "SALIDA APROBADA" ? (
                            <div className='col-12 d-flex justify-content-end'>
                              <Button
                                text='Solicitar apertura'
                                onClick={handleRequire}
                                theme='success'
                                type='button'
                                disabled={isToday}
                              />
                            </div>
                          ) : DataSelected.status_description ===
                            "SALIDA ACEPTADA" ? (
                            <div className='col-12 d-flex justify-content-end'>
                              <Button
                                text='Solicitar retorno'
                                onClick={() => {setModalConfirm(true);}}
                                theme='success'
                                type='button'
                              />
                            </div>
                          ) : null
                        }
                      >
                        <form className='row'>
                          {DataSelected.date_approval && (
                            <div className='col-12 mb-1'>
                              <h6>Fecha de solicitud de aprobación:</h6>
                              {DataSelected.date_approval}
                            </div>
                          )}
                          {DataSelected.fecha_respuesta_aprobacion && (
                            <div className='col-12 mb-1'>
                              <h6>Fecha de aprobación:</h6>
                              {DataSelected.fecha_respuesta_aprobacion}
                            </div>
                          )}
                          <div className='col-md-12 col-12 mb-1'>
                            <strong style={{color:"#475F7B"}}>Observación: </strong>
                            {DataSelected.reason_details} <br />
                          </div>
                          {DataSelected.reason_decline_description && (
                            <div className='col-12 mb-1'>
                              <strong style={{color:"#475F7B"}}>Motivo de rechazo: </strong>
                              {DataSelected.reason_decline_description}
                            </div>                
                          )}
                          {DataSelected.employee_info !== undefined ? (
                            <div className='col col-sm-12 col-md-6'>
                              <CardItem
                                data-toggle='modal'
                                data-target='#visitantModal'
                                onClick={() => {
                                  setIsModalVisible(isToday);
                                }}
                              >
                                <>
                                  <div className=' d-flex'>
                                    <div className='list-icon mr-1'>
                                      <div className='avatar bg-rgba-primary m-0'>
                                        <img
                                          className='img-fluid'
                                          src={
                                            URLAPI +
                                            DataSelected.employee_info
                                              .employee_photo
                                          }
                                          alt='img placeholder'
                                          height='38'
                                          width='38'
                                        />
                                      </div>
                                    </div>
                                    <div className='list-content'>
                                      <span className='list-title text-bold-500'>
                                        {
                                          DataSelected.employee_info
                                            .employee_full_name
                                        }
                                      </span>
                                      <small className='text-muted d-block'>
                                        Cargo:{" "}
                                        {
                                          DataSelected.employee_info
                                            .employee_job
                                        }
                                      </small>
                                      <small className='text-muted d-block'>
                                        {/* {item.ruc} */}
                                      </small>
                                    </div>
                                  </div>
                                </>
                              </CardItem>
                            </div>
                          ) : null}
                        </form>
                        {/* date_request_exit: null,
                         date_request_return: null,
                        date_response_exit: null,
                         date_response_return: null, */}
                        <form className='row'>
                          {DataSelected.date_create && (
                            <div className='col-12 mb-1'>
                              <h6>
                                Fecha de creación:{" "}
                                <strong> {DataSelected.date_create}</strong>{" "}
                              </h6>
                            </div>
                          )}
                          {DataSelected.date_approval && (
                            <div className='col-12 mb-1'>
                              <h6>
                                Fecha de respuesta de aprobación:{" "}
                                <strong> {DataSelected.date_approval}</strong>{" "}
                              </h6>
                            </div>
                          )}
                          {DataSelected.date_request_exit && (
                            <div className='col-12 mb-1'>
                              <h6>
                                Fecha de solicitud de apertura de salida:{" "}
                                <strong>
                                  {" "}
                                  {DataSelected.date_request_exit}
                                </strong>{" "}
                              </h6>
                            </div>
                          )}
                          {DataSelected.date_response_exit && (
                            <div className='col-12 mb-1'>
                              <h6>
                                Fecha de Respuesta de apertura de puerta Salida:{" "}
                                <strong>
                                  {" "}
                                  {DataSelected.date_response_exit}
                                </strong>{" "}
                              </h6>
                            </div>
                          )}
                          {DataSelected.date_request_return && (
                            <div className='col-12 mb-1'>
                              <h6>
                                Solicitud de apertura de puerta retorno:{" "}
                                <strong>
                                  {" "}
                                  {DataSelected.date_request_return}
                                </strong>{" "}
                              </h6>
                            </div>
                          )}
                          {DataSelected.date_response_return && (
                            <div className='col-12 mb-1'>
                              <h6>
                                Solicitud de apertura de puerta retorno:{" "}
                                <strong>
                                  {" "}
                                  {DataSelected.date_response_return}
                                </strong>{" "}
                              </h6>
                            </div>
                          )}
                        </form>
                      </Card>
                      {isModalVisible && (
                        <DeparteeModal
                          show={isModalVisible}
                          handleClose={() => setIsModalVisible(false)}
                          employee={DataSelected}
                          // other modal props...
                        >
                          {/* Modal content goes here */}
                        </DeparteeModal>
                      )}
                    </div>
                  </div>
                </div>
                {/* email details  end*/}
                <div className='row px-2 mb-4'>
                  <div className='col-12 px-0' />
                </div>
              </div>
            </div>
            {/*/ Detailed Email View */}
          </div>
          {/* <VisitantModal item={visitantSelected} /> */}
        </div>
      </div>
      <Modal className="modal-main div-modal" show={modalConfirm}>
        {isLoading && <LoadingSpinner/>}
        <div className="card-body">
            <div className="text-center">
                <h5>¿Está seguro de que va a retornar un colaborador a la joyería?</h5>
            </div>
        </div>
        <div className="card-footer text-center">
          <button type="button" onClick={() => {setModalConfirm(false);}} className={`btn btn-danger mr-1`}>NO</button>
          <button type="button" onClick={handleRequireRetorno} className={`btn btn-success`}>SI</button>
        </div>
      </Modal>
      <Modal className="modal-main div-modal" show={modal}>
        {isLoading && <LoadingSpinner/>}
        <div className="card-body">
          <h5>Seleccione un empleado</h5>
          <SelectInput
              label="Empleado"
              name="id_empleado"
              options={DataSelected.employees.map((emp) => ({
                  name: emp.name,
                  id: emp.id,
              }))}
              onChange={handleChangeEmployee}
          />
        </div>
        <div className="card-footer text-center">
          <button type="button" onClick={() => {setModal(false);}} className={`btn btn-danger mr-1`}>Cancelar</button>
          <button type="button" onClick={handleRequireRecurrent} className={`btn btn-success`}>Aceptar</button>
        </div>
      </Modal>
    </div>
  );
};

export default DepartureDetails;
