import React, { useEffect, useState } from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  updateDepartureReason,
  clearDeparture,
  UPDATE_DEPARTURE_REASON,
  getAllDeparturesReasons,
} from "../../../redux/departures";
import LoadingSpinner from "../../../components/loading-spinner";
import Checkbox from "../../../components/check-box";

/** @module  Pages/departure_reasons/UpdateReason */

const UpdateReason = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { _update_departure_reason, _get_all_departures_reasons } = useSelector(
    (state) => state.departures_module
  );
  const { token } = useSelector((state) => state.users_module.login.data) || null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (_get_all_departures_reasons) {
      if (_get_all_departures_reasons.status === 200) {
        if (JSON.stringify(_get_all_departures_reasons.data) !== "{}") {
          // eslint-disable-next-line
          _get_all_departures_reasons.data.datos.map((reasons) => {
            if (reasons.id === parseInt(id)) {
              setForm(reasons);
            }
          });
        }
      }
    }
  }, [_get_all_departures_reasons, id]);

  useEffect(() => {
    if (_update_departure_reason) {
      if (_update_departure_reason.status === 200) {
        toast.success("Actualizado correctamente");
        dispatch(clearDeparture(UPDATE_DEPARTURE_REASON));
        setTimeout(() => {
          history.push("/razones");
          dispatch(getAllDeparturesReasons(token));
        }, 1000); // 2-second delay
        setIsLoading(false);
      } else if (_update_departure_reason.status !== 0) {
        if (_update_departure_reason.message) {
          toast.error(_update_departure_reason.message);
          dispatch(clearDeparture(UPDATE_DEPARTURE_REASON));
          setIsLoading(false);
        }
      }
    }
  }, [_update_departure_reason, dispatch, history, token]);

  const [form, setForm] = useState({
    descripcion: "",
    id_tipo: "",
    salida_sin_aprobacion: false,
  });

  const handleChange = (e) => {
    if (e.target.name === "activo" || e.target.name === "salida_sin_aprobacion") {
      setForm({
          ...form,
          [e.target.name]: e.target.checked
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    //validate fields
    if (form.id_tipo === "") {
      toast.error("Debe seleccionar el tipo");
      setIsLoading(false);
      return;
    }
    if (form.descripcion === "") {
      toast.error("Debe ingresar la descripción");
      setIsLoading(false);
      return;
    }
    dispatch(updateDepartureReason(token, id, form));
  };

  return (
    <div className='app-content content'>
      <ToastContainer />
      {isLoading && <LoadingSpinner/>}
      <div className='content-overlay' />

      <div className='content-wrapper'>
        <div className='content-header row'>
          <div className='content-header-left col-12 mb-2 mt-1'>
            <Breadcrumbs
              title='Razones'
              items={[{ label: "Razones", link: "/razones" }]}
            />
          </div>
        </div>
        <div className='content-body'>
          <div className='row'>
            <div className='col-12'>
              <form className='card' onSubmit={handleSubmit}>
                <div className='card-header'>
                  <h4 className='card-title'>Actualizar Razón</h4>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label>tipo de salida</label>
                      <select
                        className='form-control'
                        onChange={handleChange}
                        id='id_tipo'
                        name='id_tipo'
                      >
                        <option value='' hidden>Seleccionar tipo</option>
                        <option value='1' selected={(form.tipo === 'EMERGENCIA') ? true : false}>EMERGENCIA</option>
                        <option value='2' selected={(form.tipo === 'PROGRAMADA') ? true : false}>PROGRAMADA</option>
                        <option value='3' selected={(form.tipo === 'RECURRENTE') ? true : false}>RECURRENTE</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <Input
                        id='descripcion'
                        name='descripcion'
                        label='Descripción'
                        value={form.descripcion}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Checkbox
                          id="activo"
                          label="Activo"
                          name="activo"
                          onChange={handleChange}
                          state={form.activo}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Checkbox
                          id="salida_sin_aprobacion"
                          label="Salida sin aprobación"
                          name="salida_sin_aprobacion"
                          onChange={handleChange}
                          state={form.salida_sin_aprobacion}
                      />
                    </div>
                  </div>
                </div>
                <div className='card-footer d-flex justify-content-end'>
                  <Link to='/razones' className='btn btn-danger m-1'>
                    Cancelar
                  </Link>

                  <Button
                    type='submit'
                    className='btn btn-success'
                    onClick={() => {}}
                    text='Guardar'
                    theme='success'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateReason;
