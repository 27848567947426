import React, { useState, useEffect } from "react";
import Index from "./pages/user-auth";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./login";
import ResetPassword from "./pages/auth/reset-password";
import ActivateAccount from "./pages/auth/activate-account";
import UploadPhoto from "./pages/auth/upload-photo";
import ConfirmPhoto from "./pages/auth/confirm-photo";
import RequestResetPassword from "./pages/auth/request-reset-password";
import { SocketContext, socket } from "./config/ws-client";
import packageJson from '../package.json';

function App() {
  const _login = useSelector((state) => state.users_module.login);
  const _session = useSelector((state) => state.users_module._session_data);
  const [login, setLogin] = useState(_login);
  const [username, setUsername] = useState("general");

  function caching () {
    let version = localStorage.getItem('version');
    if(version !== packageJson.version) {
        if('caches' in window) {
            caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
            });
    
            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
        localStorage.clear();
        localStorage.setItem('version',packageJson.version);
    }
};

  useEffect(() => {
    setLogin(_login);
    caching();
    if (_session.data) {
      setUsername(_session.data.usuario);
    }
  }, [_login, _session]);

  return (
    <Router>
      
      
      {login.authenticated ? (
        <SocketContext.Provider value={{ socket: socket(username) }}>
          <Switch>
            <Route path='/' component={Index} />
          </Switch>
        </SocketContext.Provider>
      ) : (
        <Switch>
          <Route exact path='/recuperar-contrasena'>
            <RequestResetPassword />
          </Route>
          <Route exact path='/confirmar-contrasena/:uid/:token/'>
            <ResetPassword />
          </Route>
          <Route exact path='/confirmar/foto/'>
            <ConfirmPhoto />
          </Route>
          <Route exact path='/subir-foto/:id/:code/'>
            <UploadPhoto />
          </Route>
          <Route exact path='/activar-cuenta/:user_id/:token/'>
            <ActivateAccount />
          </Route>
          <Route path='/' component={Login} />
          <Redirect to='/' />
        </Switch>
      )}
    </Router>
  );
}

export default App;
